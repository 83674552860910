import DeviceUtility from "./device";

Number.prototype.isBetween = function (a, b) {
	const min = Math.min(a, b);
	const max = Math.max(a, b);
	return this >= min && this <= max;
};

export function safeGetItem(key) {
	try {
		return localStorage.getItem(key);
	} catch (e) {
		console.error("Error accessing localStorage:", e);
		return null;
	}
}

export function safeSetItem(key, value) {
	try {
		localStorage.setItem(key, value);
		return true;
	} catch (e) {
		console.error("Error setting localStorage item:", e);
		return false;
	}
}

export function updateFabButton(bottom) {
	const fabButton = document.getElementById("fab-button");
	if (bottom && fabButton && DeviceUtility.isMobile) {
		fabButton.style.display = `none`;
	} else {
		fabButton.style.display = "inline flex";
	}
}

export function beautifyName(name) {
	if (!name) {
		return "";
	}
	return name
		.split(" ")
		.map((n) => n[0].toUpperCase() + n.slice(1))
		.join(" ");
}

export function getCustomerDisplayName(record) {
	if (!record) {
		return "";
	}
	if (record.name && record.name.trim()) {
		return record.name;
	} else if (record.phone) {
		return record.phone;
	} else if (record.email) {
		return record.email;
	} else if (record.PhoneNumbers && record.PhoneNumbers.length > 0) {
		return record.PhoneNumbers[0].phoneNumber;
	} else if (record.EmailAddresses && record.EmailAddresses.length > 0) {
		return record.EmailAddresses[0].emailAddress;
	}
	return "";
}
export function getUserAvatarBackgroundColor(comment) {
	return comment.agent ? comment.agent.color : getSeededAvatarColor(comment.User?.name ?? "");
}

export function setStartedByColumn(item, userId) {
	const x = item.User;
	if (!x) {
		return "";
	}
	let resp = x.firstname ? x.firstname + " " + x.lastname : x.email;
	if (x.id == userId) {
		resp += " (You)";
	}
	return resp;
}

export function getAgentNameInitials(agentName) {
	if (!agentName) return "-";
	const names = agentName.split(" ");
	if (names.length === 1) {
		return names[0].split("@")[0].substring(0, 2).toUpperCase();
	}
	return names
		.map((x) => x[0])
		.join("")
		.slice(0, 2)
		.toUpperCase();
}

export function getDisplayDate(comment) {
	return new Date(comment.createdAt).toLocaleDateString();
}

export function getSeededAvatarColor(name) {
	if (!name) return "primary";
	const avatarColors = [
		"#E6BB74",
		"#96B081",
		"#6B729D",
		"#798287",
		"#80736F",
		"#DAA56E",
		"#7AA17C",
		"#C0C285",
		"#7A6AA1",
		"#6A94BC",
		"#61A5AC",
	];
	const index = name.split(" ").reduce((acc, curr) => acc + curr.charCodeAt(0), 0) % avatarColors.length;
	return avatarColors[index];
}

export function addEmailToCustomer(customer, emailAddress) {
	if (!emailAddress) {
		return customer;
	}
	// If the customer's list of email addresses is empty, add the new email address.
	if (customer.EmailAddresses.length === 0) {
		customer.EmailAddresses.push({ emailAddress: emailAddress });
	}
	// If the email address is empty, remove it from the list.
	else if (emailAddress.length === 0) {
		customer.EmailAddresses.splice(0, 1);
	}
	// Otherwise, update the existing email address.
	else {
		customer.EmailAddresses[0] = customer.EmailAddresses[0] || {};
		customer.EmailAddresses[0].emailAddress = emailAddress;
	}

	return customer;
}

export function addPhoneNumberToCustomer(customer, phoneNumber) {
	if (!phoneNumber) {
		return customer;
	}
	// If the customer's list of phone numbers is empty, add the new phone number.
	if (customer.PhoneNumbers.length === 0) {
		customer.PhoneNumbers.push({ phoneNumber: phoneNumber });
	}
	// If the phone number is empty, remove it from the list.
	else if (phoneNumber.length === 0) {
		customer.PhoneNumbers.splice(0, 1);
	}
	// Otherwise, update the existing phone number.
	else {
		customer.PhoneNumbers[0] = customer.PhoneNumbers[0] || {};
		customer.PhoneNumbers[0].phoneNumber = phoneNumber;
	}

	return customer;
}
