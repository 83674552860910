<template>
	<div>
		<div>
			<v-list-item class="padding-0" :class="{ 'row-updated': comment.$updated, 'highlight-comment': isEditing }">
				<v-list-item-content class="comment mobile">
					<div class="d-flex justify-space-between" v-if="showReplyBox">
						<div class="heading">Replies</div>
						<div class="pr-4">
							<v-icon @click="openReplyBox"> icon-close-small </v-icon>
						</div>
					</div>
					<div class="d-flex justify-space-between">
						<div class="d-flex number-container justify-space-between">
							<div class="d-flex align-center">
								<v-avatar
									:color="getSeededAvatarColor(setStartedByColum(commentData))"
									class="team-avatar"
									:style="{ 'background-color': getUserAvatarBackgroundColor(commentData) }">
									{{ getAgentNameInitials(setStartedByColum(commentData)) }}
								</v-avatar>
								{{ setStartedByColum(commentData) }}
								<div class="date">{{ getDisplayDate(commentData) }}</div>
							</div>
							<v-menu
								v-if="isMyComment && !commentData.deletedAt && !isViewOnly"
								v-model="moreOptionsOpened"
								:z-index="10000">
								<template v-slot:activator="{ on, attrs }">
									<v-btn elevation="0" class="mr-2" v-bind="attrs" v-on="on" id="more-actions">
										<v-icon>icon-dots-horizontal-more</v-icon>
									</v-btn>
								</template>
								<v-list rounded class="more-actions">
									<v-list-item @click="toggleEditComment" class="hover-item">
										<v-btn
											v-if="isMyComment"
											v-show="me?.id != commentData.User?.id"
											size="x-small"
											class="no-pointer-events"
											elevation="0"
											color="transparent">
											<v-icon>icon-pencil-edit-stroke</v-icon>
											<span>Edit</span>
										</v-btn>
									</v-list-item>
									<v-list-item
										@click="() => deleteComment(commentData.id, isReply)"
										class="hover-item">
										<v-btn
											v-if="isMyComment"
											v-show="me?.id != commentData.User?.id"
											size="x-small"
											class="no-pointer-events"
											elevation="0"
											color="transparent">
											<v-icon color="red">icon-trash-full-stroke</v-icon>
											Delete
										</v-btn>
									</v-list-item>
								</v-list>
							</v-menu>
						</div>
					</div>
					<div class="comment-size">
						<div class="d-flex name">
							<div class="comment-name">
								<span
									v-for="(comment, idx) in getCommentParts()"
									:key="idx"
									:class="{ mention: idx % 2 === 1 }"
									:style="idx % 2 == 1 ? 'white-space: pre;' : ''">
									{{ comment }}
								</span>
							</div>
						</div>

						<div class="d-flex team-member comment-row">
							<v-btn
								v-if="!isReply && !showReplyBox && !commentData.deletedAt && !isViewOnly"
								elevation="0"
								@click="openReplyBox">
								<span>Reply</span>
							</v-btn>
						</div>
						<v-btn v-if="totalReplies > 0 && !showReplyBox" elevation="0" @click="openReplyBox">
							{{ buttonLabel }}
						</v-btn>
					</div>
				</v-list-item-content>
			</v-list-item>
			<v-list-item class="editing-comment" v-if="isEditing" :class="{ 'row-updated': commentData.$updated }">
				<v-list-item-content>
					<AddComment
						@update:addComment="updateComment"
						@cancelReply="openReplyBox"
						@closeEdit="toggleEditComment"
						@update:editComment="updateComment"
						:edit-comment="commentData" />
				</v-list-item-content>
			</v-list-item>
		</div>

		<!--- Show count of replies below card  --->

		<div v-show="showReplyBox">
			<div v-if="totalReplies > 0" class="replies-line">
				<div>{{ totalReplies }} {{ totalReplies > 1 ? "replies" : "reply" }}</div>
				<div class="line"></div>
			</div>
			<div class="comments-container" :class="getContainerClass">
				<CommentAction
					v-for="reply in replies"
					:key="reply.id"
					:is-view-only="isViewOnly"
					:me="me"
					:users="users"
					:comment="reply"
					:is-reply="true"
					:light-box-view="lightBoxView"
					@edit-comment="editReply"
					@delete-comment="() => deleteComment(reply.id)" />
			</div>
			<AddComment
				class="full-width"
				v-if="!isEditingReply && !isViewOnly"
				:placeholder="'Reply...'"
				:light-box-view="lightBoxView"
				@update:addComment="addComment"
				@cancelReply="openReplyBox"
				:is-reply="true" />
		</div>
	</div>
</template>

<script>
	import api from "@/api";
	import AddComment from "./AddComment.vue";
	import {
		getUserAvatarBackgroundColor,
		setStartedByColumn,
		getAgentNameInitials,
		getDisplayDate,
		getSeededAvatarColor,
	} from "../helpers/utilities";
	import * as Sentry from "@sentry/vue";
	export default {
		components: {
			AddComment,
		},
		name: "CommentAction",
		props: {
			isViewOnly: {
				type: Boolean,
				required: true,
			},
			comment: {
				type: Object,
				required: true,
			},
			isReply: {
				type: Boolean,
				default: false,
			},
			me: {
				type: Object,
				required: true,
			},
			users: {
				type: Array,
				required: true,
			},
			lightBoxView: {
				type: Boolean,
				required: true,
			},
		},
		computed: {
			shouldCommentExist() {
				return this.commentData.deletedAt === null || this.commentData.replyCount > 0;
			},
			getBoxClass() {
				return {
					"light-box": this.lightBoxView,
				};
			},
			getContainerClass() {
				return {
					"light-container": this.lightBoxView,
				};
			},
			buttonLabel() {
				return this.showReplyBox
					? "Hide Replies"
					: `--- View ${this.commentData.replyCount} ${
							this.commentData.replyCount > 1 ? "replies" : "reply"
					  }`;
			},
			totalReplies() {
				return this.commentData.replyCount;
			},
			commentContent() {
				let comment = this.commentData.content ?? "";
				if (comment.includes("@")) {
					for (const user of this.users) {
						if (comment.includes(`@${user.id}`)) {
							comment = comment.replace(`@${user.id}`, `<split>@${user.name}<split>`);
						}
					}
				}
				return comment;
			},
		},
		data() {
			return {
				showReplyBox: false,
				isEditing: false,
				replies: [],
				isMyComment: false,
				isEditingReply: false,
				commentData: null,
				moreOptionsOpened: false,
			};
		},
		created() {
			this.commentData = this.comment;
		},
		async mounted() {
			this.isMyComment = this.me?.user.id === this.comment.User.id;
		},
		methods: {
			getSeededAvatarColor(name) {
				return "#E6BB74";
			},
			editReply(replyData, isEditingReply) {
				this.isEditingReply = isEditingReply;
			},
			toggleEditComment() {
				this.isEditing = !this.isEditing;
				this.moreOptionsOpened = false;
				this.$emit("edit-comment", this.commentData, this.isEditing);
			},
			getCommentParts() {
				if (this.commentData.deletedAt) return ["[Deleted]"];
				return this.commentContent.split("<split>");
			},
			getDisplayDate(comment) {
				return getDisplayDate(comment);
			},
			toggleMoreOptions() {
				this.moreOptionsOpened = !this.moreOptionsOpened;
			},
			getUserAvatarBackgroundColor(comment) {
				return getUserAvatarBackgroundColor(comment);
			},
			setStartedByColum(item) {
				return setStartedByColumn(item, this.me?.user.id);
			},
			getAgentNameInitials(agentName) {
				return getAgentNameInitials(agentName);
			},
			async updateComment(updatedComment) {
				this.commentData = await api.updateComment(this.commentData.id, { content: updatedComment });
				this.moreOptionsOpened = false;
				this.isEditing = false;
				this.$emit("edit-comment", this.commentData, this.isEditing);
			},
			async deleteComment(commentId, isReply = false) {
				if (!isReply) {
					if (await this.$root.$dangerzone.open("Delete Comment", "This will delete the comment.")) {
						await api.deleteComment(commentId);
						if (this.commentData.id !== commentId) {
							this.commentData.replyCount--;
							this.openReplyBox({ forced: this.commentData.replyCount > 0 });
						} else {
							this.$emit("delete-comment", commentId);
							if (this.showReplyBox) {
								this.openReplyBox({});
							}
						}
					} else {
						return;
					}
				} else {
					this.$emit("delete-comment", commentId);
				}
			},
			async addComment(comment) {
				const reply = await api.createComment({
					parentId: this.commentData.id,
					parentType: "comment",
					content: comment,
				});
				this.replies.push(reply);
				this.commentData.replyCount++;
				this.openReplyBox({ forced: true });
			},
			async openReplyBox({ forced }) {
				try {
					if (forced || this.showReplyBox === false) {
						if (this.isEditing) {
							this.toggleEditComment();
						}
						const replyResponse = await api.getComments({
							where: { parentId: this.commentData.id },
							order: [["createdAt", "ASC"]],
							pageSize: 50,
						});
						this.replies = replyResponse.rows.filter((c) => c.deletedAt === null);
						this.showReplyBox = true;
					} else {
						this.showReplyBox = false;
					}
					this.$emit("toggle-reply-box", this.showReplyBox, this.commentData.id);
				} catch (error) {
					Sentry.captureException(error, {
						tags: { component: "CommentAction", method: "openReplyBox" },
					});
				}
			},
		},
		watch: {
			comment: {
				handler() {
					this.commentData = this.comment;
				},
				deep: true,
			},
			moreOptionsOpened: {
				handler() {},
			},
		},
	};
</script>

<style scoped>
	.highlight-comment {
		background-color: #f0e9ff;
	}
	.comment-box {
		display: flex;
		flex-direction: column;
	}
	.hover-item {
		transition: background-color 0.3s ease;
	}

	.hover-item:hover {
		background-color: #f5f5f5;
	}

	.no-pointer-events {
		pointer-events: none;
	}
	.v-list-item {
		min-height: 0;
		padding: 0px 8px 0px 0px !important;
	}
	.date {
		font-size: 0.8rem;
		color: #999;
		margin-left: 10px;
	}
	.team-member {
		font-size: 0.8rem;
	}
	.v-list-item__content {
		padding: 0 !important;
	}
	.light-box {
		margin-bottom: unset !important;
		max-height: unset !important;
		height: 100%;
	}
	.mention {
		color: var(--mention-comment);
		cursor: pointer;
	}
	.team-avatar {
		width: 30px;
		height: 30px;
		font-size: 0.8rem;
	}
	.number-container {
		font-size: 0.8rem;
		margin-bottom: 0rem;
		width: 100%;
	}
	.comment-row {
		margin-top: 10px;
	}

	.theme--light.v-btn.v-btn--has-bg {
		background-color: transparent;
	}

	.v-btn:hover {
		background-color: transparent;
	}

	.v-btn:not(.v-btn--round).v-size--default {
		min-width: 0;
		padding: 0;
	}
	.replies-line {
		display: flex;
		align-items: center;
		color: black;
		opacity: 0.6;
		padding-bottom: 12px;
	}

	.replies-line .line {
		flex-grow: 1;
		height: 1px;
		background-color: black;
		opacity: 0.6;
		margin-left: 10px;
	}
	.updated-date {
		font-size: 0.8rem;
	}
	.row-updated {
		background-color: #f5f5f5;
	}

	.comment-name {
		max-width: 100%;
	}

	.comment-size {
		overflow-wrap: break-word;
		max-width: 80%;
		margin-left: 60px;
	}
	.team-avatar {
		display: flex;
		justify-content: center;
		font-size: 0.75em;
		font-weight: 600;
		color: #fff;
		margin-right: 10px;
		min-width: 40px !important;
		max-width: 40px !important;
		max-height: 40px !important;
		text-transform: uppercase;
	}

	.heading {
		font-family: "Inter Tight";
		font-size: 1.25rem;
		font-style: normal;
		font-weight: 600;
		margin-bottom: 16px;
	}

	.comment-card {
		margin-bottom: 5px;
	}

	.padding-0 {
		padding: 0 !important;
	}

	@media (min-width: 960px) {
		.v-list-item.editing-comment {
			padding: 0px !important;
		}
	}
	@media (max-width: 959px) {
		.light-container {
			margin-bottom: 0rem !important;
		}
		.comments-container {
			flex: 1 1 auto;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			min-height: 50vh;
			overscroll-behavior-y: contain;
			touch-action: pan-y;
			margin-bottom: 5rem;
		}
	}
</style>
