<template>
	<div class="comment-box" :class="getBoxClass">
		<div>
			<div
				ref="commentHeader"
				@touchstart="handleTouchStartForCommentHeader"
				@touchmove="handleTouchMoveForCommentHeader"
				@touchend="handleTouchEnd"
				class="d-flex flex-column justify-center"
				v-if="this.$vuetify.breakpoint.smAndDown">
				<div class="line"></div>
				<div class="d-flex justify-center text-subtitle-1 font-weight-bold">Comments</div>
			</div>
			<v-card-title v-else class="pl-0">
				<div class="d-flex media-header mb-4" :class="isSticky ? 'pb-10' : ''">
					<v-icon>icon-chat-stroke</v-icon>
					<span class="heading">Comments ({{ showCount }})</span>
				</div>
			</v-card-title>
			<AddComment
				ref="addCommentRef"
				:class="getAddCommentClass"
				v-if="canAddComment && $vuetify.breakpoint.mdAndUp && !lightBoxView"
				@update:addComment="addComment"
				:lightBoxView="lightBoxView" />
			<div
				class="comments-container"
				ref="commentsContainer"
				@touchstart="handleTouchStart"
				@touchmove="handleTouchMove"
				@touchend="handleTouchEnd"
				:style="pullStyle"
				:class="getContainerClass">
				<CommentsList
					v-if="!isViewOnly && (comments.length > 0 || loadingComments)"
					@toggle-reply-box="toggleReplyBox"
					:comments="comments"
					:is-view-only="isViewOnly"
					:allow-more="allowMore"
					:light-box-view="lightBoxView"
					@update-comment="updateComment"
					@edit-comment="editComment"
					@load-more-comments="loadMoreComments"
					@delete-comment="deleteComment" />
				<NoCommentView
					:is-view-only="isViewOnly"
					:light-box-view="lightBoxView"
					v-else-if="!isViewOnly && comments.length === 0" />
				<div class="d-flex justify-center h-100" v-else-if="loadingComments">
					<v-icon>icon-loading-dots-circle</v-icon>
				</div>
			</div>
		</div>
		<AddComment
			ref="addCommentRef"
			:class="getAddCommentClass"
			v-if="canAddComment && ($vuetify.breakpoint.smAndDown || lightBoxView)"
			@update:addComment="addComment"
			:lightBoxView="lightBoxView" />
	</div>
</template>

<script>
	import AddComment from "./AddComment.vue";
	import CommentsList from "./CommentsList.vue";
	import NoCommentView from "./NoCommentView.vue";
	import DeviceUtility from "@/helpers/device";
	import api from "@/api";
	export default {
		components: {
			AddComment,
			CommentsList,
			NoCommentView,
		},
		props: {
			parentId: {
				type: String,
				required: true,
			},
			parentType: {
				type: String,
				required: true,
			},
			lightBoxView: {
				type: Boolean,
				default: false,
			},
			isViewOnly: {
				type: Boolean,
				default: false,
			},
			conversationId: {
				type: String,
				default: null,
			},
		},
		data() {
			return {
				comments: [],
				commentCount: 0,
				loadingComments: true,
				isReplyBoxOpen: false,
				isEditing: false,
				atBottom: false,
				touchStartY: 0,
				currentPull: 0,
				isPulling: false,
				pullThreshold: 100,
				isSticky: false,
				currentPages: 1,
				currentCount: 0,
				showCount: 0,
				hysteresisOffset: 20,
				scrollThreshold: 1050,
				stickyCommentStyle: {},
				commentBoxTop: {},
			};
		},
		beforeDestroy() {
			// Ensure the commentsContainer exists before removing the event listener
			if (this.$refs.commentsContainer) {
				this.$refs.commentsContainer.removeEventListener("touchmove", this.handleTouchMove);
			}
			if (DeviceUtility.isDesktop) {
				window.removeEventListener("scroll", this.checkStickyPosition);
			}
		},
		async mounted() {
			if (DeviceUtility.isDesktop) {
				window.addEventListener("scroll", this.checkStickyPosition);
			}
			this.scrollThreshold = window.innerHeight;
			if (this.lightBoxView) {
				this.$nextTick(() => {
					// Check if the ref is available before adding the event listener
					if (this.$refs.commentsContainer) {
						this.$refs.commentsContainer.addEventListener("touchmove", this.handleTouchMove, {
							passive: false,
						});
					}
				});
			}

			await this.loadComments(); // Ensure comments are loaded after setting up the scroll
		},
		methods: {
			async loadMoreComments() {
				this.currentPages++;
				await this.loadComments();
			},
			checkStickyPosition() {
				this.commentBoxTop = this.$refs.commentsContainer?.getBoundingClientRect().top;
				const headerLeft = document.querySelector(".project-date")?.getBoundingClientRect().left || 0;
				console.log(this.isSticky, headerLeft);
				if (this.commentBoxTop > this.scrollThreshold && !this.isSticky) {
					this.scrollThreshold -= this.hysteresisOffset;
					this.isSticky = true;
					this.stickyCommentStyle = {
						left: `${headerLeft - 8}px`,
					};
				} else if (this.commentBoxTop < this.scrollThreshold + this.hysteresisOffset && this.isSticky) {
					this.isSticky = false;
					this.stickyCommentStyle = {};
					this.scrollThreshold += this.hysteresisOffset;
				}
			},
			handleTouchStartForCommentHeader(event) {
				const container = this.$refs.commentHeader;
				this.handleTouchStart(event, container);
			},
			handleTouchStart(event, container = this.$refs.commentsContainer) {
				if (!this.lightBoxView) {
					return;
				}
				if (container.scrollTop === 0) {
					this.touchStartY = event.touches[0].clientY;
					this.isPulling = true;
				}
			},
			handleTouchMoveForCommentHeader(event) {
				const container = this.$refs.commentHeader;
				this.handleTouchMove(event, container);
			},
			handleTouchMove(event, container = this.$refs.commentsContainer) {
				if (container.scrollTop === 0 && container.scrollHeight > container.clientHeight) {
					if (event.touches[0].screenY > event.touches[0].screenY) {
						event.preventDefault();
					}
				} else if (container.scrollTop + container.clientHeight === container.scrollHeight) {
					if (event.touches[0].screenY < event.touches[0].screenY) {
						event.preventDefault();
					}
				}
				if (this.isPulling && container.scrollTop === 0) {
					const touchY = event.touches[0].clientY;
					const pull = touchY - this.touchStartY;

					// Only allow pulling down, not up
					if (pull > 0) {
						// Add resistance to the pull
						this.currentPull = pull / 2;
						event.preventDefault();
					}
				}
				event.stopPropagation();
			},
			handleTouchEnd() {
				if (!this.lightBoxView) {
					return;
				}
				if (this.isPulling) {
					if (this.currentPull > this.pullThreshold) {
						this.$emit("close-comment-box");
					}

					// Reset pull state
					this.currentPull = 0;
					this.isPulling = false;
				}
			},
			async loadComments() {
				const comments = await api.getComments({
					where: { parentId: this.parentId },
					pageSize: 50 * this.currentPages,
				});
				this.currentCount = comments.rows.length;
				this.comments = comments.rows.filter((c) => c.deletedAt === null || c.replyCount > 0);
				this.commentCount = comments.count;
				this.showCount = this.comments.length;
				this.loadingComments = false;
			},
			async updateComment(comment) {
				const updatedCommentIndex = this.comments.findIndex((c) => c.id === comment.id);
				this.comments[updatedCommentIndex] = comment;
			},
			async editComment(comment, isEditing) {
				this.isEditing = isEditing;
			},
			toggleReplyBox(isOpen, commentId) {
				this.isReplyBoxOpen = isOpen;
			},
			async deleteComment(id) {
				await this.loadComments();
			},
			async addComment(comment) {
				const body = {
					parentId: this.parentId,
					parentType: this.parentType,
					content: comment,
				};
				if (this.conversationId) {
					body.conversationId = this.conversationId;
				}
				await api.createComment(body);
				await this.loadComments();
			},
		},
		watch: {
			parentId: async function (newVal) {
				await this.loadComments();
			},
		},
		computed: {
			canAddComment() {
				return !this.isReplyBoxOpen && !this.isEditing && !this.isViewOnly;
			},
			allowMore() {
				return this.commentCount > this.currentCount;
			},
			getAddCommentClass() {
				return "";
			},
			getBoxClass() {
				return {
					"light-box": this.lightBoxView,
				};
			},
			getContainerClass() {
				return {
					"light-container": this.lightBoxView,
					"new-comments-container": this.lightBoxView,
					pulling: this.isPulling,
					sticky: this.isSticky,
				};
			},
			pullStyle() {
				return {
					transform: this.currentPull ? `translateY(${this.currentPull}px)` : "none",
					transition: this.isPulling ? "none" : "transform 0.2s",
				};
			},
		},
	};
</script>

<style scoped>
	.media-header {
		align-items: center;
		margin-bottom: 16px;
		color: #03011d;
	}

	.media-header .v-icon {
		width: 28px;
		height: 28px;
		color: #0070ff;
		/* margin-left: 12px; */
		margin-right: 8px;
		padding: 6px;
	}

	.media-header .heading {
		font-family: "Inter Tight";
		font-size: 1.25rem;
		font-style: normal;
		font-weight: 600;
		line-height: 1.2;
	}

	.h-100 {
		height: 100%;
	}
	.line {
		height: 2px;
		display: flex;
		align-items: center;
		align-self: center;
		margin-bottom: 8px;
		background-color: #9b9ea5;
		border-radius: 12px;
		opacity: 1;
		width: 24px;
	}
	.comments-container {
		transition: transform 0.2s ease-out;
	}

	.sticky-add-comment {
		position: fixed;
		bottom: 0;
		left: 16.9vw;
		width: 55% !important;
		background-color: white;
		z-index: 10;
	}
	.sticky {
		padding-top: 20px;
	}
	.comment-box {
		display: flex;
		flex-direction: column;
	}

	.light-box {
		justify-content: space-between;
		height: 100%;
	}

	@media (min-width: 960px) {
		.new-comments-container {
			max-height: 40vh;
			overflow-y: auto;
		}
	}

	@media (max-width: 959px) {
		.comment-box {
			padding: 16px 8px !important;
		}
		.comments-container {
			flex: 1 1 auto;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			min-height: 0;
			overscroll-behavior-y: contain;
			touch-action: pan-y;
			margin-bottom: 5rem;
		}

		.light-container {
			margin-bottom: 0rem !important;
		}

		.light-box {
			margin-bottom: unset !important;
			max-height: unset !important;
			height: 100%;
		}
	}

	.v-card-hide {
		background-color: #f5f5f5 !important;
	}
</style>
