<template>
	<v-card v-if="getEditCommentLength() == 0" flat :elevation="0" class="add-comment mt-6" :class="getCardClass">
		<Mentionable :keys="['@']" :items="users" offset="6" insert-space placement="top-start" :limit="3">
			<div class="textarea-wrapper">
				<v-textarea
					v-model="comment"
					@input="onInput"
					ref="commentField"
					:placeholder="placeholder"
					rounded
					outlined
					:counter="255"
					:maxLength="255"
					:disabled="comment.length > 255"
					class="custom-text-field"
					@keydown="editNotesKeyDown"
					append-icon="icon-paper-plane-stroke"
					@click:append="addComment"
					@keydown.ctrl.enter="addComment"
					@keydown.meta.enter="addComment"
					auto-grow
					:max-rows="4"
					:rows="1"
					:class="{ 'icon-disabled': comment.length === 0 }">
				</v-textarea>
			</div>

			<template #no-result> </template>

			<template #item-@="{ item }">
				<div class="user">
					<span class="dim"> {{ item.name }} </span>
				</div>
				<div class="replies-line">
					<div class="line"></div>
				</div>
			</template>
		</Mentionable>
	</v-card>
	<div v-else class="page add-comment d-flex justify-space-between" :class="getCardClass">
		<v-avatar
			v-if="isDesktop"
			:color="getSeededAvatarColor(setStartedByColum(editComment))"
			class="team-avatar"
			:style="{ 'background-color': getUserAvatarBackgroundColor(editComment) }">
			{{ getAgentNameInitials(setStartedByColum(editComment)) }}
		</v-avatar>
		<div class="rounded-border">
			<div v-if="isDesktop" class="d-flex align-center pl-2 pt-2">
				{{ setStartedByColum(editComment) }}
				<div class="date">{{ getDisplayDate(editComment) }}</div>
			</div>
			<Mentionable :keys="['@']" :items="users" offset="6" insert-space :limit="3">
				<div class="textarea-wrapper">
					<v-textarea
						v-model="comment"
						@input="onInput"
						ref="commentField"
						:outlined="false"
						:placeholder="placeholder"
						:counter="255"
						:maxLength="255"
						:disabled="comment.length > 255"
						class="custom-text-field"
						@keydown="editNotesKeyDown"
						@click:append="addComment"
						background-color="transparent"
						:max-rows="4"
						:rows="4"
						solo
						flat
						:class="{ 'icon-disabled': comment.length === 0 }">
					</v-textarea>
				</div>

				<template #no-result> </template>

				<template #item-@="{ item }">
					<div class="user">
						<span class="dim"> {{ item.name }} </span>
					</div>
					<div class="replies-line">
						<div class="line"></div>
					</div>
				</template>
			</Mentionable>
			<div class="d-flex justify-end">
				<v-btn rounded outlined class="" size="x-large" color="black" elevation="0" @click="closeEdit"
					>Cancel</v-btn
				>
				<v-btn
					rounded
					outlined
					:disabled="comment == editComment.content || comment.length == 0"
					@click="editCommentText"
					color="white"
					:class="updateClass"
					elevation="0"
					>Save</v-btn
				>
			</div>
		</div>
	</div>
</template>

<script>
	import api from "@/api";
	import { Mentionable } from "vue-mention";
	import { getSeededAvatarColor } from "../helpers/utilities";
	import {
		getUserAvatarBackgroundColor,
		setStartedByColumn,
		getAgentNameInitials,
		getDisplayDate,
	} from "../helpers/utilities";
	import DeviceUtility from "../helpers/device";

	export default {
		components: {
			Mentionable,
		},
		data() {
			return {
				comment: "",
				users: [],
				filteredUsers: [],
				showMentions: false,
				currentUserSelection: null,
				commentPlaceholders: {},
				highlightedParts: [],
			};
		},
		props: {
			isReply: {
				type: Boolean,
				default: false,
			},
			placeholder: {
				type: String,
				default: "Type to leave a comment...",
			},
			editComment: {
				type: Object,
				default: () => ({}),
			},
			lightBoxView: {
				type: Boolean,
				default: false,
			},
		},
		async mounted() {
			// add a listener to the body to adjust the height of the body when the keyboard is shown
			this.$refs.commentField?.$refs.input.addEventListener("focus", this.adjustHeight);
			const usersResponse = await api.getUsers({ limit: 1000 });
			this.users = usersResponse.rows;
			this.users = this.users.map((user) => ({ name: user.name, id: user.id, value: user.id }));
			if (this.editComment?.content?.length > 0) {
				this.comment = this.editComment.content;
				this.onInput();
			}
		},
		beforeDestroy() {
			this.$refs.commentField?.$refs.input.removeEventListener("focus", this.adjustHeight);
		},
		methods: {
			adjustHeight() {
				document.body.style.height = `${window.innerHeight}px`;
			},
			getSeededAvatarColor(name) {
				return getSeededAvatarColor(name);
			},
			editNotesKeyDown(e) {
				if (["ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown"].includes(e.code)) {
					e.stopPropagation();
				}
			},
			getEditCommentLength() {
				return this.editComment?.content?.length || 0;
			},
			getUserAvatarBackgroundColor(comment) {
				return getUserAvatarBackgroundColor(comment);
			},
			setStartedByColum(item) {
				return setStartedByColumn(item, this.me?.user.id);
			},
			getAgentNameInitials(agentName) {
				return getAgentNameInitials(agentName);
			},
			getDisplayDate(comment) {
				return getDisplayDate(comment);
			},
			onInput() {
				let comment = this.comment;
				if (comment.includes("@")) {
					for (const user of this.users) {
						if (comment.includes(`@${user.id}`)) {
							comment = comment.replace(`@${user.id}`, `@${user.name}`); // we replace the id with the name while rendering the comment.
							this.commentPlaceholders[user.name] = user.id;
						}
					}
				}
				this.$nextTick(() => {
					if (!this.$refs.commentField.style) {
						this.$refs.commentField.style = {};
					}
					this.$refs.commentField.style.height = "auto";
					this.$refs.commentField.style.height = `${this.$refs.commentField?.scrollHeight}px`;
				});
				this.comment = comment;
			},
			selectUser(user) {
				const cursorPos = this.$refs.commentField.$el.querySelector("input").selectionStart;
				const textBeforeCursor = this.comment.slice(0, cursorPos);
				const atPos = textBeforeCursor.lastIndexOf("@");
				if (atPos > -1) {
					this.comment = textBeforeCursor.slice(0, atPos) + `@${user.name} ` + this.comment.slice(cursorPos);
					this.currentUserSelection = { id: user.id, name: user.name, pos: atPos };
					this.showMentions = false;
				}
			},
			addComment() {
				this.updateComment(false);
			},
			editCommentText() {
				this.updateComment(true);
			},
			closeEdit() {
				this.$emit("closeEdit");
			},
			updateComment(isEditing) {
				let comment = this.comment.trim();
				if (comment.trim().length === 0) {
					return;
				}
				for (const [name, id] of Object.entries(this.commentPlaceholders)) {
					comment = comment.replace(`@${name}`, `@${id}`); // while saving the comment, we replace the name with the id.
				}
				if (isEditing) {
					this.$emit("update:editComment", comment);
				} else {
					this.$emit("update:addComment", comment);
				}
				this.comment = "";
			},
			cancelReply() {
				this.$emit("cancelReply");
			},
		},
		computed: {
			getCardClass() {
				let cardClass = "";
				if (!this.lightBoxView) {
					cardClass += " add-comment-card";
				}
				if (this.editComment?.content?.length > 0) {
					cardClass += " edit-comment-card";
				}
				return cardClass;
			},
			updateClass() {
				if (this.comment == this.editComment.content || this.comment == "") {
					return "update-button-disabled";
				}
				return "background-color-save";
			},
			isDesktop() {
				return DeviceUtility.isDesktop;
			},
		},
	};
</script>

<style lang="css" scoped>
	@media (max-width: 959px) {
		:deep(.comment-controls) {
			height: 5% !important;
		}
		.edit-comment-card {
			width: 100% !important;
			bottom: 0rem !important;
			padding-left: 8px !important;
			padding-right: 8px !important;
		}
	}
	.textarea-wrapper {
		position: relative;
		max-height: calc(var(--max-rows) * 3rem);
		overflow-y: auto;
	}

	.custom-text-field >>> textarea {
		overflow-y: auto !important;
		max-height: calc(var(--max-rows) * 3rem) !important;
	}
	.icon-disabled .v-input__append-inner {
		opacity: 0.5;
		pointer-events: none;
		cursor: not-allowed;
		color: red;
		background-color: blue;
	}
	.add-comment {
		justify-content: space-between;
		filter: unset !important;
	}
	.date {
		font-size: 0.8rem;
		color: #999;
		margin-left: 10px;
	}
	.team-avatar {
		display: flex;
		justify-content: center;
		font-size: 0.75em;
		font-weight: 600;
		color: #fff;
		margin-right: 10px;
		min-width: 40px !important;
		max-width: 40px !important;
		max-height: 40px !important;
		text-transform: uppercase;
	}

	.theme--light.v-btn.v-btn--disabled.v-btn--has-bg.update-button-disabled {
		background-color: transparent !important;
	}

	.theme--light.v-btn {
		margin-bottom: 10px !important;
		margin-top: 5px !important;
		margin-right: 10px !important;
	}

	.theme--light.v-btn.v-btn--disabled.v-btn--has-bg.update-button-disabled {
		background-color: transparent !important;
	}

	.v-card.page {
		padding: 0px !important;
		width: 95% !important;
		margin-left: 2.5%;
	}

	.custom-text-field >>> .v-input__control > .v-input__slot {
		padding: 0 12px !important;
	}

	.add-comment-card {
		position: fixed;
		left: 0;
		justify-content: center;
		z-index: 50;
		padding-left: 10px;
		bottom: 7.6rem;
	}
	.background-color-save {
		background-color: #0070ff !important;
		color: white !important;
	}

	.edit-comment-card {
		bottom: 7.5rem;
		background-color: #f5f5f5 !important;
		width: 100% !important;
	}

	@media (min-width: 960px) {
		.theme--light.v-btn {
			margin-bottom: 10px !important;
			margin-top: 5px !important;
			margin-right: 10px !important;
		}

		.add-comment-card {
			position: relative;
			bottom: 0 !important;
			width: 100% !important;
		}

		.sticky-header {
			z-index: 100;
			background-color: white; /* Or match your app's background */
			padding: 1rem;
		}

		.v-card.page {
			padding: 0px !important;
			width: 95% !important;
		}

		.edit-comment-card {
			padding-left: 0px !important;
			position: relative;
			margin-top: 12px !important;
			background-color: transparent !important;
		}
	}

	.tooltip {
		display: block !important;
		z-index: 10000;
	}

	.replies-line {
		display: flex;
		align-items: center;
		color: black;
		opacity: 0.6;
	}

	.replies-line .line {
		flex-grow: 1;
		height: 1px;
		background-color: black;
		opacity: 0.6;
		margin-left: 10px;
	}

	.user {
		color: black;
		font-size: medium;
		margin: 10px;
		min-width: 90vw;
	}

	.custom-text-field {
		position: relative;
	}

	:deep(.custom-text-field .v-input__slot textarea) {
		min-height: 0 !important;
		max-height: 100px !important;
	}

	.rounded-border {
		border: 1px solid black;
		border-radius: 16px 16px 0px 0px;
		width: 100%;
	}

	@media (min-width: 960px) {
		.rounded-border {
			border-bottom: 1px solid black;
			border-radius: 10px;
		}
	}

	.text-highlight {
		position: absolute;
		top: 0;
		left: 0;
		padding: 16px;
		pointer-events: none;
		width: 100%;
		color: transparent;
	}

	.input-overlay {
		background-color: transparent;
	}

	.tooltip .tooltip-inner {
		background: black;
		color: white;
		border-radius: 16px;
		padding: 5px 10px 4px;
	}

	.v-input.v-input--hide-details {
		margin-bottom: 0px !important;
	}

	.tooltip .tooltip-arrow {
		width: 100;
		height: 100;
		border-style: solid;
		position: absolute;
		margin: 5px;
		border-color: black;
		z-index: 1;
	}

	body {
		font-family: "Avenir", Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		margin: 32px;
	}

	.input {
		width: 100%;
	}

	.mention-item {
		padding: 4px 10px;
		border-radius: 4px;
	}

	.mention-selected {
		background: rgb(192, 250, 153);
	}

	.tooltip[x-placement^="top"] {
		margin-bottom: 5px;
	}

	.tooltip[x-placement^="top"] .tooltip-arrow {
		border-width: 5px 5px 0 5px;
		border-left-color: transparent !important;
		border-right-color: transparent !important;
		border-bottom-color: transparent !important;
		bottom: -5px;
		left: calc(50% - 5px);
		margin-top: 0;
		margin-bottom: 0;
	}

	.tooltip[x-placement^="bottom"] {
		margin-top: 5px;
	}

	.tooltip[x-placement^="bottom"] .tooltip-arrow {
		border-width: 0 5px 5px 5px;
		border-left-color: transparent !important;
		border-right-color: transparent !important;
		border-top-color: transparent !important;
		top: -5px;
		left: calc(50% - 5px);
		margin-top: 0;
		margin-bottom: 0;
	}

	.tooltip[x-placement^="right"] {
		margin-left: 5px;
	}

	.tooltip[x-placement^="right"] .tooltip-arrow {
		border-width: 5px 5px 5px 0;
		border-left-color: transparent !important;
		border-top-color: transparent !important;
		border-bottom-color: transparent !important;
		left: -5px;
		top: calc(50% - 5px);
		margin-left: 0;
		margin-right: 0;
	}

	.tooltip[x-placement^="left"] {
		margin-right: 5px;
	}

	.tooltip[x-placement^="left"] .tooltip-arrow {
		border-width: 5px 0 5px 5px;
		border-top-color: transparent !important;
		border-right-color: transparent !important;
		border-bottom-color: transparent !important;
		right: -5px;
		top: calc(50% - 5px);
		margin-left: 0;
		margin-right: 0;
	}

	.tooltip.popover .popover-inner {
		background: #f9f9f9;
		color: black;
		padding: 4px;
		border-radius: 5px;
		text-align: left;
		box-shadow: 0 0 5px #00000055, 0 5px 30px #00000033;
	}

	.tooltip.popover .popover-arrow {
		border-color: #f9f9f9;
	}

	.tooltip[aria-hidden="true"] {
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.15s, visibility 0.15s;
	}

	.tooltip[aria-hidden="false"] {
		visibility: visible;
		opacity: 1;
		transition: opacity 0.15s;
	}
</style>
