import { beautifyName } from "./utilities";
import moment from "moment";
import api from "@/api";
export function getNotificationTitle(notification) {
	switch (notification.type) {
		case "comment": {
			const comment = notification.metadata.commentCreated;
			return `${beautifyName(comment.User.name)} commented on ${beautifyName(notification.parentType)}`;
		}
		case "mention": {
			const comment = notification.metadata.commentCreated;
			return `${beautifyName(comment.User.name)} mentioned you in a comment`;
		}
		case "update": {
			const user = notification.metadata.updatedBy;
			const difference = notification.metadata?.difference;
			if (!user) {
				return `${notification.parentType} updated`;
			}
			if (!difference || difference.length === 0) {
				return `${beautifyName(user.name)} updated ${beautifyName(notification.parentType)}`;
			}
			return `${beautifyName(user.firstname)} changed ${beautifyName(notification.parentType)} ${Object.keys(
				difference
			)
				.map((key) => `${key}`)
				.join(", ")}`;
		}
		case "share": {
			const user = notification.metadata.updatedBy;
			return `${beautifyName(user.firstname)} shared a ${beautifyName(
				notification.parentType
			)} with you as ${beautifyName(notification.metadata.projectShare.accessType)}`;
		}
		default:
			return `${beautifyName(notification.parentType)} ${notification.type}`;
	}
}

export function getNotificationIcon(notification) {
	switch (notification.type) {
		case "mention":
		case "comment":
			return "icon-comment-solid";
		default:
			return "icon-table-stroke";
	}
}

export function getNotificationTime(notification) {
	// relative time
	return moment(notification.createdAt).fromNow();
}

export async function clickNotification(notification, nav) {
	// redirect based on the notification type
	console.log(notification);
	await api.updateNotification(notification.id, { isRead: true });
	switch (notification.parentType) {
		case "project":
			nav(`/projects/${notification.parentId}`);
			break;
		case "conversation":
			nav(`/conversations/${notification.parentId}`);
			break;
		case "mention":
		case "comment": {
			const comment = notification.metadata.commentCreated;
			let url = "";
			const conversationId = comment.conversationId;
			switch (comment.parentType) {
				case "conversation":
					url = `/conversations/${comment.parentId}`;
					break;
				case "project":
					url = `/projects/${comment.parentId}`;
					break;
				default:
					if (conversationId) {
						url = `/conversations/${conversationId}?artifactId=${comment.parentId}&artifactType=${comment.parentType}`;
					}
					break;
			}
			nav(url);
			break;
		}
		default:
			break;
	}
}
