<template>
	<div>
		<div>
			<CoolLightBox
				ref="lightbox"
				:items="recordingList"
				:index="lightBoxIndex"
				:class="actionClass"
				:gallery="false"
				srcName="recordingUrl"
				thumbsPosition="left"
				@close="lightBoxClose"
				@on-change="onLightBoxChange"
				style="background-color: #000; backdrop-filter: blur(8px)">
			</CoolLightBox>
			<div v-if="video && lightBoxIndex != null" class="video-slider-lightbox">
				<v-slider
					v-model="percent"
					color="white"
					track-color="#FFFFFF52"
					thumb-color="white"
					id="seek-slider"
					min="0"
					:max="maxSliderValue"
					:thumb-size="48"
					:class="{ scrubbing: isScrubbing }"
					@click="sliderClicked"
					@input="videoScrubbed">
				</v-slider>
			</div>
			<div
				v-if="video && lightBoxIndex != null && !isViewOnly"
				class="video-buttons-lightbox"
				@click="controlsClicked">
				<v-btn id="play" icon :data-state="!isPlaying ? 'play' : 'pause'" @click="play">
					<v-icon>{{ isPlaying ? "icon-pause" : "icon-play-solid" }}</v-icon>
				</v-btn>
				<div class="progress">
					<span class="current-time">
						{{ toHoursAndMinutes(video.currentTime) }}
					</span>
					/
					<span class="total-time">
						{{ toHoursAndMinutes(duration) }}
					</span>
				</div>
				<v-btn id="mute" icon :data-state="isMuted ? 'unmute' : 'mute'" @click="mute">
					<v-icon>{{ isMuted ? "icon-volume-slash-solid" : "icon-volume-solid" }}</v-icon>
				</v-btn>
				<v-btn id="snapshot" icon :disabled="video?.currentTime == 0" @click="$emit('take-snapshot')">
					<v-icon>icon-snapshot</v-icon>
				</v-btn>
				<v-btn id="fullscreen" icon @click="toggleFullscreen">
					<v-icon>{{ isFullScreen ? "icon-fullscreen-exit" : "icon-fullscreen" }}</v-icon>
				</v-btn>
				<v-menu top left v-model="showMoreControls">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							:id="`more-controls-btn-${videoId}`"
							icon
							v-bind="attrs"
							v-on="on"
							@click="showMoreControls = true">
							<v-icon>icon-dots-vertical-more</v-icon>
						</v-btn>
					</template>

					<v-list class="more-controls">
						<v-list-item v-if="canPictureInPicture">
							<v-list-item-title @click="startPictureInPicture"
								><i class="icon-picture-in-picture" /> Picture in picture</v-list-item-title
							>
						</v-list-item>
						<v-list-item>
							<v-menu
								top
								right
								:nudge-left="140"
								:max-height="300"
								:attach="`#more-controls-btn-${videoId}`"
								v-model="showSpeedControls">
								<template v-slot:activator="{ on, attrs }">
									<v-list-item-title
										v-bind="attrs"
										v-on="on"
										@click="
											showSpeedControls = true;
											showMoreControls = false;
										"
										><i class="icon-playback-speed" /> Playback speed</v-list-item-title
									>
								</template>

								<v-list class="speed-list">
									<v-list-item>
										<v-list-item-title @click="goBackToMoreControls"
											><i class="icon-chevron-left" /> Options</v-list-item-title
										>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 0.25 }">
										<v-list-item-title @click="changePlaybackSpeed(0.25)">0.25</v-list-item-title>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 0.5 }">
										<v-list-item-title @click="changePlaybackSpeed(0.5)">0.5</v-list-item-title>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 0.75 }">
										<v-list-item-title @click="changePlaybackSpeed(0.75)">0.75</v-list-item-title>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 1 }">
										<v-list-item-title @click="changePlaybackSpeed(1)">Normal</v-list-item-title>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 1.25 }">
										<v-list-item-title @click="changePlaybackSpeed(1.25)">1.25</v-list-item-title>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 1.5 }">
										<v-list-item-title @click="changePlaybackSpeed(1.5)">1.5</v-list-item-title>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 1.75 }">
										<v-list-item-title @click="changePlaybackSpeed(1.75)">1.75</v-list-item-title>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 2 }">
										<v-list-item-title @click="changePlaybackSpeed(2)">2</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>
			<div
				v-if="lightBoxIndex != null && !isViewOnly"
				v-show="!cropping && !annotating"
				class="lightbox-controls">
				<div>
					<v-tooltip
						v-if="this.$vuetify.breakpoint.smAndDown"
						bottom
						content-class="lightbox-tooltip"
						:open-on-hover="!isTouch"
						:open-on-click="false"
						:open-on-focus="false">
						<template v-slot:activator="{ on, attrs }">
							<v-btn elevation="0" icon v-bind="attrs" v-on="on" @click="startComment">
								<v-icon>icon-chat-stroke</v-icon>
							</v-btn>
						</template>
						<span>Comment</span>
					</v-tooltip>
					<v-tooltip bottom content-class="lightbox-tooltip">
						<template v-slot:activator="{ on, attrs }">
							<v-btn elevation="0" icon v-bind="attrs" v-on="on" @click="share(recordingId)">
								<v-icon>icon-link-double</v-icon>
							</v-btn>
						</template>
						<span>Copy Link</span>
					</v-tooltip>
				</div>
				<div>
					<v-tooltip bottom content-class="lightbox-tooltip">
						<template v-slot:activator="{ on, attrs }">
							<v-btn elevation="0" icon v-bind="attrs" v-on="on" @click="download">
								<v-icon>icon-download</v-icon>
							</v-btn>
						</template>
						<span>Download</span>
					</v-tooltip>

					<v-tooltip bottom content-class="lightbox-tooltip">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								elevation="0"
								icon
								v-bind="attrs"
								v-on="on"
								@click="() => deleteRecording(lightBoxIndex)">
								<v-icon color="red">icon-trash-full-stroke</v-icon>
							</v-btn>
						</template>
						<span>Delete</span>
					</v-tooltip>
				</div>
			</div>
		</div>
		<div v-if="lightBoxOpen && isCommentBoxOpen" class="comment-controls" :style="commentBoxStyle">
			<v-card v-if="recordingId" class="page desktop-right">
				<!-- <v-icon @click="startComment">icon-close-circle-solid</v-icon> -->
				<CommentBox
					@close-comment-box="startComment"
					:parent-id="recordingId"
					:parent-type="'video'"
					:conversation-id="recordings[lightBoxIndex]?.conversationId"
					:light-box-view="true" />
			</v-card>
		</div>
	</div>
</template>

<script>
	import CoolLightBox from "vue-cool-lightbox";
	import api from "../api";
	import DeviceUtility from "@/helpers/device";
	import CommentBox from "./CommentBox.vue";
	import timezones from "../helpers/timezones";

	export default {
		components: {
			CoolLightBox,
			CommentBox,
		},
		props: {
			recordings: {
				type: Array,
				required: true,
			},
			bulkSelect: {
				type: Boolean,
				required: false,
			},
			lightboxId: {
				type: String,
				required: false,
			},
			showGallery: {
				type: Boolean,
				default: false,
			},
			isViewOnly: {
				type: Boolean,
				default: false,
			},
			parentId: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				areCustomControlSupported: false,
				canPictureInPicture: true,
				canPlay: false,
				currentPlaybackSpeed: 1,
				duration: 0,
				figureId: null,
				isDownloading: false,
				isFullScreen: false,
				isMuted: false,
				isPlaying: false,
				isScrubbing: false,
				maxSliderValue: 100,
				percent: 0,
				playPromise: undefined,
				progress: 0,
				showMoreControls: false,
				showSpeedControls: false,
				wasPlaying: false,
				waiting: false,
				saving: false,
				editNotes: false,
				recordingNotes: "",
				recordingList: [],
				canSaveNotes: false,
				lightBoxIndex: null,
				lightBoxOpen: false,
				cropping: false,
				annotating: false,
				savingAnnotation: false,
				savingCrop: false,
				isTouch: false,
				dirty: false,
				commenting: false,
				isLightboxThumbsOpen: false,
				isUnauthenticated: false,
				backgroundImage: "",
				filename: String,
				source: String,
				videoId: String,
				disablePlayback: Boolean,
				sourceCommentWidth: -1,
				backgroundImageCaption: {
					title: "",
					description: "",
				},
				video: {
					muted: false,
					paused: false,
					ended: false,
					currentTime: 0,
				},
				isDesktop: DeviceUtility.isDesktop,
				commentBoxStyle: { display: "none" },
				conversation: null,
			};
		},
		async mounted() {
			this.recordingList = this.recordings.map((r) => {
				return {
					...r,
					title: timezones.formatDate(r.recordingStart),
					recordingUrl: r.recordingUrl + "?ext=.mp4",
					mediaType: "webVideo",
					ext: "mp4",
				};
			});
			this.$watch(
				() => this.$refs.lightbox?.showThumbs,
				(newVal) => {
					this.isLightboxThumbsOpen = newVal;
				}
			);
		},
		computed: {
			isCommentBoxOpen() {
				return this.commenting || this.$vuetify.breakpoint.mdAndUp;
			},
			recordingId() {
				return this.recordingList[this.lightBoxIndex]?.id;
			},
			actionClass() {
				if (this.cropping) {
					return "cropping";
				} else if (this.annotating) {
					return "annotating";
				} else {
					return "comment";
				}
			},
		},
		methods: {
			watchForImageLoad() {
				const imageElement = document.querySelector(
					".cool-lightbox .cool-lightbox__slide.cool-lightbox__slide--current video"
				);
				if (imageElement) {
					this.updateCommentBoxPosition();
				} else {
					console.debug("Video element not found");
				}
			},
			updateCommentBoxPosition() {
				const imageElement = document.querySelector(
					".cool-lightbox .cool-lightbox__slide.cool-lightbox__slide--current .cool-lightbox-video"
				);
				console.log(imageElement);
				if (imageElement) {
					const imageLeft = this.setImageStyle(imageElement);
					const { width, height, top } = imageElement.getBoundingClientRect();
					this.commentBoxStyle = {
						top: `${top}px !important`,
						height: `${height}px !important`,
						left: `${imageLeft + width}px !important`,
					};
					const captionBox = document.querySelector(".cool-lightbox-caption");
					this.setVideoSliderStyle(imageLeft, width, height, top);
					if (captionBox) {
						this.setCaptionStyle(captionBox, imageLeft, width + 350, height, top);
					}
				}
			},
			setCaptionStyle(captionElement, imageLeft, imageWidth, height, top) {
				const avatarElement = document.querySelector(".avatar");
				captionElement.style.position = `fixed`;
				captionElement.style.top = `${top + height - 1}px`;
				captionElement.style.left = `${imageLeft}px`;
				captionElement.style.width = `${imageWidth}px`;
				captionElement.style.height = `100px`;
				captionElement.style.padding = `16px`;
				captionElement.style.backgroundColor = `#fff`;
				captionElement.style.borderRadius = `0px 0px 12px 12px`;
				if (avatarElement) {
					avatarElement.style.top = `${top + height + 16}px`;
					avatarElement.style.left = `${imageLeft + 16}px`;
				}
			},
			setImageStyle(imageElement) {
				const { width } = imageElement.getBoundingClientRect();
				const screenCenter = window.innerWidth / 2;
				const imageLeft = Math.max(screenCenter - width - 16, 30);
				imageElement.style.left = `${imageLeft}px`;
				imageElement.style.position = `fixed`;
				imageElement.style.padding = `16px 0px 16px 16px`;
				imageElement.style.backgroundColor = `#000`;
				imageElement.style.borderRadius = `12px 0px 0px 0px`;
				return imageLeft;
			},
			setVideoSliderStyle(imageLeft, imageWidth, height, top) {
				const sliderElement = document.querySelector(".video-slider-lightbox");
				sliderElement.style.position = `fixed`;
				sliderElement.style.top = `${top + height - 100}px`;
				sliderElement.style.left = `${imageLeft}px`;
				sliderElement.style.width = `${imageWidth}px`;
				sliderElement.style.height = `100px`;
				sliderElement.style.padding = `16px`;
				sliderElement.style.borderRadius = `0px 0px 12px 12px`;
				const videoButtonsElement = document.querySelector(".video-buttons-lightbox");
				videoButtonsElement.style.position = `fixed`;
				videoButtonsElement.style.top = `${top + height - 70}px`;
				videoButtonsElement.style.left = `${imageLeft}px`;
				videoButtonsElement.style.width = `${imageWidth}px`;
				videoButtonsElement.style.padding = `16px`;
				videoButtonsElement.style.borderRadius = `0px 0px 12px 12px`;
			},
			async share(recordingId) {
				this.$emit("share", recordingId);
			},
			async videoScrubbed(value) {
				//turn off transition time
				this.wasPlaying = !this.video.paused;
				this.isScrubbing = true;
				if (!this.waiting) {
					await this.pause();
				}
				this.video.currentTime = (value / this.maxSliderValue) * this.duration;
				window.setTimeout(() => {
					if (this.wasPlaying && !this.waiting) {
						this.playPromise = this.video.play();
					}
					this.wasPlaying = false;
					this.isScrubbing = false;
				}, 100);
			},
			sliderClicked(e) {
				e.preventDefault();
				e.stopPropagation();
			},
			goBackToMoreControls() {
				window.setTimeout(() => {
					this.showSpeedControls = false;
					this.showMoreControls = true;
					console.log("hiding speed controls, showing more controls");
				}, 0);
			},
			async startPictureInPicture() {
				try {
					if (this.video !== document.pictureInPictureElement) {
						await this.video.requestPictureInPicture();
					}
					window.setTimeout(() => {
						this.showSpeedControls = false;
						this.showMoreControls = false;
						console.log("hiding speed controls, hiding more controls");
					}, 0);
				} catch (error) {
					console.error(error);
				}
			},
			async toggleFullscreen() {
				if (
					document.fullscreenElement ||
					document.webkitFullscreenElement ||
					document.mozFullScreenElement ||
					document.webkitCurrentFullScreenElement
				) {
					// The document is in fullscreen mode
					// document.exitFullscreen();
					if (document.exitFullscreen) {
						document.exitFullscreen();
					} else if (document.mozCancelFullScreen) {
						/* Firefox */
						document.mozCancelFullScreen();
					} else if (document.webkitExitFullscreen) {
						/* Chrome, Safari and Opera */
						document.webkitExitFullscreen();
					} else if (document.msExitFullscreen) {
						/* IE/Edge */
						document.msExitFullscreen();
					}
					this.setFullscreenData(false);
					this.isFullScreen = false;
				} else {
					// The document is not in fullscreen mode

					let timeout = 1;

					// Workaround for iOS issue. Start playing the video prior to requesting full screen.
					if (DeviceUtility.isIOS) {
						await this.play();
						timeout = 200;
					}

					setTimeout(() => {
						if (this.videoContainer.requestFullscreen) {
							this.videoContainer.requestFullscreen();
						} else if (this.videoContainer.mozRequestFullScreen) {
							/* Firefox */
							this.videoContainer.mozRequestFullScreen();
						} else if (this.videoContainer.webkitRequestFullscreen) {
							/* Chrome, Safari and Opera */
							this.videoContainer.webkitRequestFullscreen();
						} else if (this.video.webkitEnterFullScreen) {
							this.video.webkitEnterFullScreen();
						} else if (this.videoContainer.msRequestFullscreen) {
							/* IE/Edge */
							this.videoContainer.msRequestFullscreen();
						}
						this.setFullscreenData(true);
						this.isFullScreen = true;
					}, timeout);
				}
			},
			mute() {
				this.isMuted = !this.isMuted;
				this.video.muted = !this.video.muted;
			},
			async play() {
				if (!this.canPlay) {
					console.warn("Tried playing video before canPlay is set.");
					return;
				}
				console.log("play", this.video);
				const isPlaying =
					this.video &&
					this.video.currentTime > 0 &&
					!this.video.paused &&
					!this.video.ended &&
					this.video.readyState > this.video.HAVE_CURRENT_DATA;

				if (!isPlaying) {
					console.log("this play");
					this.playPromise = this.video.play();
				} else {
					await this.pause();
				}
			},
			async pause() {
				if (this.playPromise !== undefined) {
					await this.playPromise;
					this.video.pause();
				} else {
					this.video.pause();
				}
			},
			controlsClicked(e) {
				e.stopPropagation();
				e.preventDefault();
			},
			toHoursAndMinutes(totalSeconds) {
				const totalMinutes = Math.floor(totalSeconds / 60);
				const seconds = (totalSeconds % 60).toFixed(0).toString();
				const hours = Math.floor(totalMinutes / 60).toString();
				const minutes = (totalMinutes % 60).toString();
				if (hours > 0) {
					return `${hours}:${minutes.padStart(2, "0")}:${seconds.padStart(2, "0")}`;
				}
				return `${minutes}:${seconds.padStart(2, "0")}`;
			},
			// check if video is mp4
			checkIsMp4(url) {
				if (this.lightBoxIndex === null) {
					return false;
				}

				const str = new String(url);
				const videoExtensions = [".mp4", ".mov", ".webm", ".ogg", ".avi"];
				const isValidExtension =
					videoExtensions.filter((videoExtension) => {
						return str.indexOf(videoExtension) !== -1 || str.indexOf(videoExtension.toUpperCase()) !== -1;
					}).length > 0;
				if (isValidExtension) {
					return url;
				}

				return false;
			},

			// if is video get extension
			getVideoExt(url) {
				if (this.lightBoxIndex === null) {
					return false;
				}

				const str = new String(url);
				if (str.indexOf(".mp4") !== -1 || str.indexOf(".mov") !== -1) {
					return "mp4";
				}

				if (str.indexOf(".webm") !== -1) {
					return "webm";
				}

				if (str.indexOf(".ogg") !== -1) {
					return "ogg";
				}

				if (str.indexOf(".avi") !== -1) {
					return "avi";
				}

				return false;
			},

			// check if item is object
			checkIfIsObject() {
				const itemIndex = this.lightBoxIndex;
				const item = this.recordingList[itemIndex];
				if (typeof item === "object" && item !== null) {
					return true;
				}
				return false;
			},
			getItemSrc() {
				const imgIndex = this.lightBoxIndex;
				if (imgIndex === null) {
					return false;
				}

				const item = this.recordingList[imgIndex];
				if (this.checkIfIsObject(imgIndex)) {
					return item["recordingUrl"];
				}

				return item;
			},
			startComment() {
				this.commenting = !this.commenting;
			},
			hideThumbnailTray() {
				this.$refs.lightbox.showThumbs = false;
			},
			getRecordingId() {
				return this.recordingList[this.lightBoxIndex]?.id;
			},
			openLightBox(id) {
				if (!id || !this.recordingList || this.recordingList.length === 0) {
					return;
				}
				this.lightBoxIndex = this.recordingList.findIndex((s) => s.id === id);
				if (this.lightBoxIndex < 0) {
					return;
				}
				this.recordingList = this.recordings.map((r) => {
					return {
						...r,
						title: timezones.formatDate(r.recordingStart),
						recordingUrl: r.recordingUrl + "?ext=.mp4",
						mediaType: "webVideo",
						ext: "mp4",
					};
				});
				this.lightBoxOpen = true;
				this.onLightBoxChange(this.lightBoxIndex);
				if (DeviceUtility.isMobile || DeviceUtility.getDeviceType() === "tablet") {
					this.$refs.lightbox.canZoom = false;
				}

				// if we are in mobile then we have to close the thumbnail tray after selecting a new image
				if (this.isLightboxThumbsOpen && window.innerWidth < 960) {
					this.$refs.lightbox.showThumbs = false;
				}
			},
			async saveNotes(recordingNotes) {
				const recording = this.recordings[this.lightBoxIndex];

				let request = { notes: recordingNotes };
				await api.updateRecording(recording.id, request);

				recording.description = recordingNotes;
				this.editNotes = false;
				this.recordingNotes = "";
				this.canSaveNotes = false;
			},
			onNotesChanged(notes) {
				const recording = this.recordings[this.lightBoxIndex];
				this.canSaveNotes = notes != recording.description;
			},
			lightBoxClose() {
				this.editNotes = false;
				this.recordingNotes = "";
				this.canSaveNotes = false;
				this.lightBoxOpen = false;
				this.lightBoxIndex = null;
			},
			onLightBoxChange(index) {
				this.lightBoxIndex = index;
				this.$nextTick(() => {
					this.video = document.querySelector(`.cool-lightbox__slide--current video`);
					this.video.removeAttribute("controls");
					this.video.removeAttribute("controlslist");
					const source = this.video.querySelector("source");
					if (source) {
						const src = source.getAttribute("src");
						source.removeAttribute("src");
						this.video.crossOrigin = "anonymous";
						source.setAttribute("src", src);
						this.video.load();
					} else {
						this.video.crossOrigin = "anonymous";
					}
					this.video.addEventListener("loadedmetadata", () => {
						this.canPlay = true;
						this.duration = this.video.duration;
						if (this.duration > 180) {
							this.maxSliderValue = 1000;
						}
					});
					this.video.addEventListener("canplay", () => {
						console.log(`can play ${this.videoId}`);
						this.canPlay = true;
						if (this.waiting) {
							this.playPromise = this.video.play();
							this.waiting = false;
						}
					});
					this.video.addEventListener("play", () => {
						this.isPlaying = true;
						this.waiting = false;
						if (this.playPromise !== undefined) {
							this.playPromise = undefined;
						}
					});

					this.video.addEventListener("pause", () => {
						this.isPlaying = false;
					});

					this.video.addEventListener("timeupdate", () => {
						this.percent = Math.floor((this.video.currentTime / this.duration) * this.maxSliderValue);
					});
					this.commentBoxStyle = { display: "none" };
					setTimeout(() => {
						if (!DeviceUtility.isDesktop) {
							return;
						}
						this.watchForImageLoad();
					}, 200);
				});
			},

			async download(e) {
				try {
					e.preventDefault();
					const me = this;
					const video = this.recordingList[this.lightBoxIndex];
					this.isDownloading = true;
					const filename = this.videoFileName(video);
					var xhr = new XMLHttpRequest();
					xhr.open("GET", video.recordingUrl, true);
					xhr.responseType = "blob";
					xhr.onload = function () {
						var urlCreator = window.URL || window.webkitURL;
						var videoUrl = urlCreator.createObjectURL(this.response);
						var tag = document.createElement("a");
						tag.href = videoUrl;
						tag.target = "_blank";
						tag.download = filename;
						document.body.appendChild(tag);
						tag.click();
						document.body.removeChild(tag);
						me.isDownloading = false;
					};
					xhr.onerror = (err) => {
						me.isDownloading = false;
						console.log("Error download video", err);
					};
					xhr.send();
				} catch (error) {
					console.error(error);
				}
			},
			videoFileName(recording) {
				return `file.${this.parentId}.${recording.id}.mp4`;
			},
			editRecording() {
				const recording = this.recordingList[this.lightBoxIndex];
				this.recordingNotes = recording.description;
				this.editNotes = true;
			},
			async deleteRecording(indexToDelete) {
				const index = indexToDelete ?? this.lightBoxIndex;
				if (await this.$root.$dangerzone.open("Delete Recording", `This will delete the recording.`)) {
					await api.deleteRecording(this.recordingList[index].id);
					this.recordingList.splice(index, 1);
					if (this.recordingList.length > 0) {
						this.lightBoxIndex = null;
						// this lets the lightbox refresh its items properly.
						// if we go from index 0 to 0 the image stays the same even though we just deleted it
						this.$nextTick(() => {
							this.lightBoxIndex = index === 0 ? 0 : Math.max(0, index - 1);
							if (this?.$refs?.lightbox) {
								this.$refs.lightbox.isZooming = false;
							}
						});
					} else {
						if (this?.$refs?.lightbox) {
							this.$refs.lightbox.close();
						}
					}
				}
			},
		},
		watch: {
			recordings: {
				handler(newVal) {
					this.recordingList = newVal.map((r) => {
						return {
							...r,
							title: timezones.formatDate(r.recordingStart),
							recordingUrl: r.recordingUrl + "?ext=.mp4",
							mediaType: "webVideo",
							ext: "mp4",
						};
					});
				},
				deep: true,
			},
			isLightboxThumbsOpen: {
				handler(newVal) {
					this.$emit("update:isLightboxThumbsOpen", newVal);
				},
			},
			lightboxId: {
				handler(newVal) {
					this.openLightBox(newVal);
				},
			},
		},
	};
</script>

<style scoped>
	.video-controls {
		display: none;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 0 8px 8px 8px;
		background: rgb(0, 0, 0);
		background: -moz-linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) 75%,
			rgba(0, 0, 0, 0.5) 90%,
			rgba(0, 0, 0, 1) 100%
		);
		background: -webkit-linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) 75%,
			rgba(0, 0, 0, 0.5) 90%,
			rgba(0, 0, 0, 1) 100%
		);
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) 75%,
			rgba(0, 0, 0, 0.5) 90%,
			rgba(0, 0, 0, 1) 100%
		);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#000000",GradientType=1);
	}

	.video-controls.active {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	.video-controls::v-deep > .video-slider-lightbox .v-slider__thumb-container,
	.video-controls::v-deep > .video-slider-lightbox .v-slider__track-background,
	.video-controls::v-deep > .video-slider-lightbox .v-slider__track-fill {
		transition: 0.3s linear;
	}

	.video-controls::v-deep > .video-slider-lightbox > .scrubbing .v-slider__thumb-container,
	.video-controls::v-deep > .video-slider-lightbox > .scrubbing .v-slider__track-background,
	.video-controls::v-deep > .video-slider-lightbox > .scrubbing .v-slider__track-fill {
		transition: 0s;
	}

	.video-controls::v-deep > .video-slider-lightbox .v-slider--horizontal {
		margin-left: 18px;
		margin-right: 18px;
	}

	.video-controls::v-deep > .video-slider-lightbox .v-slider--horizontal .v-slider__track-container {
		height: 6px;
		border-radius: 5px;
	}

	.video-controls::v-deep > .video-slider-lightbox .v-slider--horizontal .v-slider__thumb {
		width: 18px;
		height: 18px;
		left: -9px;
	}

	.video-controls::v-deep > .video-slider-lightbox .v-slider--horizontal .v-slider__thumb:before {
		width: 32px;
		height: 32px;
		transform: scale(0);
		left: -7px;
		top: -7px;
	}

	.video-controls::v-deep
		> .video-slider-lightbox
		.v-slider--horizontal
		.v-slider__thumb-container:hover
		.v-slider__thumb:before {
		transform: scale(1);
	}

	.video-controls::v-deep > .video-slider-lightbox .v-input__slider .v-input__slot {
		margin-bottom: 0px;
	}

	.video-controls::v-deep > .video-slider-lightbox .v-input__slider .v-messages {
		display: none;
	}

	.video-controls::v-deep > .video-slider-lightbox .v-slider__track-container .v-slider__track-background {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	.video-controls::v-deep > .video-slider-lightbox .v-slider__track-container .v-slider__track-fill {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}

	.video-buttons-lightbox {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	}

	.video-slider-lightbox {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	}

	.video-buttons-lightbox > div.progress {
		flex: 1;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		color: #ffffff99;
		font-family: "Inter";
		font-weight: 400;
	}

	.video-buttons-lightbox > div.progress > .current-time {
		margin-right: 5px;
		color: #ffffff;
		font-weight: 600;
	}

	.video-buttons-lightbox > div.progress > .total-time {
		margin-left: 5px;
	}

	.video-buttons-lightbox > #snapshot:disabled i,
	.video-buttons-lightbox > #snapshot[disabled] i {
		color: #cecece !important;
	}

	.video-buttons-lightbox > .v-btn {
		background: none;
		color: #fff;
	}

	.more-controls::v-deep .v-list-item,
	.speed-list::v-deep .v-list-item {
		cursor: pointer;
	}

	.more-controls::v-deep i,
	.speed-list::v-deep i {
		color: #fff;
	}

	.more-controls::v-deep .v-list-item:hover,
	.speed-list::v-deep .v-list-item:hover {
		background-color: #00000011;
	}

	.speed-list::v-deep .v-list-item.active {
		border-left-color: #0070ff;
		border-left-style: solid;
		border-left-width: 5px;
	}

	.speed-list::v-deep .v-list-item:not(:first-child) .v-list-item__title {
		text-align: center;
	}

	@media (max-width: 415px) {
		.v-btn--icon.v-size--default {
			width: 32px;
			height: 32px;
		}
	}

	@media (max-width: 959px) {
		.v-btn--icon.v-size--default {
			width: 24px;
			height: 24px;
			margin: 0 1px;
		}

		.v-btn--icon.v-size--default .v-icon {
			font-size: 1.25rem;
		}
		:deep(.cool-lightbox-caption) {
			top: 50% !important;
		}
	}
	.video-buttons-lightbox button {
		z-index: inherit !important;
	}
	.video-buttons-lightbox {
		position: fixed;
		top: 330px;
		right: 0px;
		padding-left: 16px;
		padding-right: 16px;
		z-index: 10000;
	}
	.video-slider-lightbox {
		position: fixed;
		top: 300px;
		right: 0px;
		padding-left: 16px;
		padding-right: 16px;
		z-index: 10000;
	}
	:deep(.cool-lightbox .cool-lightbox__iframe) {
		top: 40% !important;
	}
	.comment-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		pointer-events: none;
	}

	.add-comment-card {
		position: relative;
		bottom: 0;
	}
	.lightbox-controls {
		position: fixed;
		width: 100%;
		display: flex;
		z-index: 10000;
		left: 0%;
	}

	.lightbox-owner {
		position: fixed;
		width: 100%;
		display: flex;
		z-index: 10000;
		left: 25%;
		color: #fff;
	}

	.theme--light.v-btn.v-btn--icon {
		color: #000;
	}

	.lightbox-controls > button.theme--light.v-btn.v-btn--icon {
		color: #fff;
		border-radius: 100px;
		height: 40px;
		width: 40px;
		margin: 10px 3px;
	}

	.cool-lightbox .cool-lightbox-button.cool-lightbox-button--next {
		right: 0px;
		padding: 0;
	}

	.cool-lightbox .cool-lightbox-button.cool-lightbox-button--prev {
		left: 20px;
		padding: 0;
	}

	.cool-lightbox.annotating {
		pointer-events: none;
	}
	.lightbox-controls > button.theme--light.v-btn.v-btn--icon:last-child {
		color: #ec5d43;
	}

	.lightbox-tooltip {
		font-size: 10px;
		line-height: 12px;
		padding: 5px 8px;
	}

	:deep(.cool-lightbox-toolbar) {
		display: flex;
		width: 100%;
		justify-content: space-between;
		padding: 10px;
		padding-top: 0;
		margin-right: 10px;
		flex-direction: row-reverse !important;
	}

	.cool-lightbox-toolbar > button:first-child {
		display: none;
		background-color: #03011d !important;
	}

	.cool-lightbox-toolbar > button.cool-lightbox-toolbar__btn:nth-child(2) {
		margin-left: 20px;
	}

	.cool-lightbox-toolbar:has(> button:only-child) {
		justify-content: flex-end;
	}

	.cool-lightbox-toolbar > button:only-child {
		display: block;
	}

	.cool-lightbox--show-thumbs.cool-lightbox--thumbs-left > .cool-lightbox-thumbs {
		left: 0;
	}

	.cool-lightbox .cool-lightbox__inner {
		left: 0;
	}

	.cool-lightbox--show-thumbs.cool-lightbox--thumbs-left > .cool-lightbox__inner {
		left: 280px;
	}

	.cool-lightbox--show-thumbs.cool-lightbox--thumbs-left > .cool-lightbox-thumbs {
		width: 280px;
	}

	.cool-lightbox--show-thumbs.cool-lightbox--thumbs-left ~ .lightbox-controls {
		left: calc(10% + 280px);
		width: calc(80% - 280px);
	}

	.cool-lightbox .cool-lightbox-thumbs .cool-lightbox-thumbs__list {
		display: none;
	}

	.cool-lightbox .cool-lightbox-thumbs .cool-lightbox-thumbs__list .cool-lightbox__thumb:before {
		border-color: rgba(0, 112, 255, 1);
	}

	.cool-lightbox.cropping > .cool-lightbox__inner > .cool-lightbox-toolbar,
	.cool-lightbox.cropping > .cool-lightbox__inner > .cool-lightbox__navigation {
		display: none;
	}

	.cool-lightbox.comment > .cool-lightbox__inner > .cool-lightbox-toolbar,
	.cool-lightbox.comment > .cool-lightbox__inner > .cool-lightbox__navigation {
		margin-bottom: 0px !important;
	}

	.cool-lightbox.annotating .cool-lightbox-caption,
	.cool-lightbox.annotating > .cool-lightbox__inner > .cool-lightbox-toolbar,
	.cool-lightbox.annotating > .cool-lightbox__inner > .cool-lightbox__navigation {
		display: none;
	}

	.recordings.fixed {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10010;
		width: 280px;
		height: 100vh;
		overflow-y: scroll;
	}

	.recordings.fixed button {
		background-color: #1e2128 !important;
		color: #fff;
		font-size: 12px;
	}

	.recordings.fixed > .recording-controls {
		flex-direction: column;
	}

	.recordings.fixed > .recording-controls > div:nth-child(2) > button {
		background-color: #000 !important;
	}

	.recordings.fixed > .recording-controls > div:nth-child(2) > button:first-child {
		color: #fff !important;
	}

	:deep(.cool-lightbox .cool-lightbox__slide video) {
		max-height: 50%;
		max-width: 100% !important;
		margin-top: 0% !important;
		margin-bottom: 80% !important;
		padding: 16px !important;
	}

	:deep(.cool-lightbox-caption) {
		bottom: unset;
		background: unset;
		text-align: left;
		color: #000;
	}

	.desktop-right {
		overflow-y: scroll;
		box-shadow: unset !important;
	}

	:deep(.cool-lightbox__slide.cool-lightbox__slide--current) {
		background-color: #03011d;
	}

	@media (min-width: 960px) {
		.lightbox-controls .theme--light.v-btn.v-btn--icon {
			color: #000 !important;
		}
		.cool-lightbox-toolbar > button:first-child {
			background-color: #f3f5f9 !important;
		}
		.lightbox-controls {
			left: 5%;
			width: 90%;
			top: 10px;
		}
		.theme--light.v-btn.v-btn--icon {
			color: #fff;
		}

		.desktop-right {
			overflow-y: auto;
			border-radius: 0px 12px 0px 0px !important;
			filter: unset !important;
			height: 100%;
			border-left: #fff;
		}

		.comment-controls {
			width: 350px !important;
			right: 6% !important;
		}

		:deep(.cool-lightbox__slide.cool-lightbox__slide--current) {
			background-color: #f3f5f9;
		}

		:deep(.cool-lightbox .cool-lightbox__slide__video) {
			top: 20% !important;
		}

		:deep(.cool-lightbox .cool-lightbox__slide video) {
			max-height: 70vh !important; /* 9:16 for 100%width  */
			max-width: 65% !important;
			margin-right: 40% !important;
			margin-top: 0% !important;
			margin-bottom: auto !important;
		}

		:deep(.cool-lightbox-caption) {
			align-items: flex-start !important;
			align-content: flex-start !important;
			width: fit-content;
		}

		:deep(.cool-lightbox-caption > p) {
			color: #000;
		}

		.desktop-right {
			max-height: calc(100vh - 25%);
		}

		.comment-box {
			max-height: 100% !important;
		}
	}

	.cool-lightbox {
		background-color: #f3f5f9 !important;
	}

	.comment-box {
		overflow-y: scroll;
	}

	.comment-controls {
		position: fixed;
		width: 30%;
		height: 75vh;
		top: 11vh;
		right: 3%;
		display: flex;
		flex-direction: column;
		z-index: 10000;
	}

	:deep(.cool-lightbox .cool-lightbox-button) {
		z-index: 10000000 !important;
	}

	.comment-controls .v-overlay {
		z-index: 10005;
		margin: 32px;
		border-radius: 12px;
	}

	:deep(.cool-lightbox-caption > p) {
		max-width: 100%;
		overflow-wrap: break-word;
		margin: auto;
	}

	/* this section is when the nav bar moves to the bottom of the screen */
	@media (max-width: 959px) {
		:deep(.cool-lightbox .cool-lightbox__slide .cool-lightbox__slide video) {
			position: unset;
			transform: unset !important;
			padding: 16px !important;
		}

		:deep(.cool-lightbox-toolbar > button.cool-lightbox-toolbar__btn) {
			background-color: #f3f5f9 !important;
		}

		.lightbox-controls {
			justify-content: space-between;
			top: 42%;
			padding-left: 16px;
			padding-right: 16px;
		}

		.lightbox-owner {
			justify-content: space-between;
			top: 2%;
		}

		:deep(.cool-lightbox-loading-wrapper) {
			top: 100px;
		}

		.desktop-right {
			flex: 1;
			display: flex;
			flex-direction: column;
			overflow: hidden;
		}

		html {
			-ms-overflow-style: none;
			/* IE and Edge */
			scrollbar-width: none;
			max-width: 100vw !important;
			overflow: hidden !important;
			overflow-y: auto !important;
		}
		html ::-webkit-scrollbar {
			display: none;
		}
		.comment-controls {
			width: 95vw;
			right: 2.5vw;
			height: 85vh;
			top: 6vh;
		}

		.comment-controls .v-overlay {
			margin: 10px !important;
		}

		.recordings.fixed {
			width: 100%;
			z-index: 10009;
		}

		.cool-lightbox--show-thumbs.cool-lightbox--thumbs-left > .cool-lightbox-thumbs {
			width: 100%;
			z-index: 100019;
		}

		.theme--light.v-btn.v-btn--icon {
			color: #fff;
		}

		.cool-lightbox--show-thumbs.cool-lightbox--thumbs-left ~ .lightbox-controls {
			display: none;
		}
	}
</style>
